<template>
    <v-container>
        <PageHeader :title="title" :items="items" />
        <loading-flux :value="loadingActivarDesactivarUsuario"></loading-flux>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-alert
                            :show="segundosMensajeActualizacion"
                            dismissible
                            :variant="mensaje.variant"
                            @dismissed="segundosMensajeActualizacion = 0"
                            @dismiss-count-down="
                                actualizarSegundosMensajeActualizacion
                            "
                        >
                            {{ mensaje.texto }}
                        </b-alert>
                        <div class="d-flex">
                            <div class="card-title col-auto me-auto">
                                Usuarios Bodegas
                            </div>
                            <button
                                v-if="rolModuloPermiso.escritura"
                                class="btn btn-primary col-auto"
                                @click="abrirModalNuevo"
                            >
                                <i class="mdi mdi-plus me-1"></i>
                                Nuevo
                            </button>
                        </div>
                        <div class="d-flex"></div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Mostrar&nbsp;
                                        <b-form-select
                                            class="form-select form-select-sm"
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Buscar:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Buscar..."
                                            class="
                                                form-control
                                                form-control-sm
                                                ms-2
                                            "
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                class="datatables tabla-personalizada"
                                :items="usuarioBodegas"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                select-mode="single"
                                selectable
                                @filtered="onFiltered"
                                :busy="usuarioBodegasCargando"
                            >
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner
                                            class="align-middle"
                                        ></b-spinner>
                                    </div>
                                </template>

                                <template #cell(activo)="data" v-if="rolModuloPermiso.escritura">
                                    <div
                                        class="form-check form-switch form-switch-md mb-3"
                                    >
                                        <input
                                            @click="
                                                activarDesactivar(
                                                    data.item
                                                )
                                            "
                                            class="form-check-input"
                                            type="checkbox"
                                            :checked="data.item.activo"
                                        />
                                    </div>
                                </template>

                    
                                <template #cell(accion)="data" v-if="rolModuloPermiso.escritura">
                                    <!-- <i
                                        class="
                                            bx bx-edit
                                            h3
                                            text-primary
                                            pointer
                                            me-2
                                        "
                                        @click="abrirModalEditar(data.item)"
                                    ></i> -->
                                    <i
                                        class="
                                            bx bx-trash
                                            h3
                                            text-primary
                                            pointer
                                        "
                                        @click="eliminarUsuarioBodegaLocal(data.item)"
                                    ></i>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    class="
                                        dataTables_paginate
                                        paging_simple_numbers
                                        float-end
                                    "
                                >
                                    <ul
                                        class="
                                            pagination pagination-rounded
                                            mb-0
                                        "
                                    >
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            v-model="mostrarModal"
            title="Agregar Usuario"
            hide-footer
            size="lg"
        >
            <loading-flux :value="loading"></loading-flux>
            <b-alert
                            :show="segundosMensajeActualizacionModal"
                            dismissible
                            :variant="mensaje.variant"
                            @dismissed="segundosMensajeActualizacionModal = 0"
                            @dismiss-count-down="
                                actualizarSegundosMensajeActualizacionModal
                            "
                        >
                            {{ mensaje.texto }}
                        </b-alert>
            <form @submit.prevent="submit">
                <div class="row">
                    
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="usuario">Usuario</label>
                            <multiselect
                                v-model="$v.form.usuario.$model"
                                :options="usuarios"
                                placeholder="Seleccionar Usuario"
                                value="id"
                                label="nombre_usuario"
                                :searchable="true"
                                :loading="usuariosCargando"
                                open-direction="bottom"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.usuario.$error,
                                }"
                                :custom-label="customLabel"
                            >

                            </multiselect>
                            <div
                                v-if="submitted && !$v.form.usuario.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="bodega">Bodega</label>
                            <multiselect
                                v-model="$v.form.bodega.$model"
                                :options="bodegas"
                                placeholder="Seleccionar Bodega"
                                value="codigo_bodega"
                                label="codigo_bodega"
                                :searchable="true"
                                :loading="bodegasCargando"
                                open-direction="bottom"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.bodega.$error,
                                }"
                            >
                            </multiselect>
                            <div
                                v-if="submitted && !$v.form.bodega.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button v-if="rolModuloPermiso.escritura" type="submit" variant="success" class="ms-1"
                        >Guardar</b-button
                    >
                </div>
            </form>
        </b-modal>
    </v-container>
</template>
<script>
// import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { usuarioMethods, usuarioComputed } from "@/state/helpers";
import { rolMethods, rolComputed } from "@/state/helpers";
import { cargoMethods, cargoComputed } from "@/state/helpers";
import {authMethods,authUsuarioComputed} from "@/state/helpers";
import {usuarioBodegaMethods,usuarioBodegaComputed} from "@/state/helpers";
import {bodegaMethods,bodegaComputed} from "@/state/helpers";
import {permiso} from "@/helpers/authservice/obtener-permisos";
import Swal from "sweetalert2";
import { calculateDV } from "@/assets/js/rut.js";
import Multiselect from "vue-multiselect";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    page: {
        title: "Usuarios",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        // Layout,
        PageHeader,
        Multiselect,
    },

    data() {
        return {
            loading: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            segundosMensajeActualizacionModal: 0,
            title: "Usuarios Bodegas",
            items: [
                {
                    text: "Usuarios",
                },
                {
                    text: "Usuarios Bodegas",
                    active: true,
                },
            ],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "nombre_usuario",
            sortDesc: false,
            fields: [
                {
                    key: "Accion",
                    label: "Acción",
                    sortable: false,
                },
                {
                    key: "codigo_usuario",
                    label: "Código Usuario",
                    sortable: true,
                },
                {
                    key: "nombre_usuario",
                    label: "Nombre Usuario",
                    sortable: true,
                },
                {
                    key: "email_usuario",
                    sortable: true,
                    thStyle: { width: "500px !important" },
                },
                {
                    key: "codigo_bodega",
                    label: "Código Bodega",
                },
                {
                    key: "nombre_bodega",
                    sortable: true,
                },
            ],
            usuarioSeleccionado: {},
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            usuarios: [],
            usuariosCargando: false,
            usuarioBodegas: [],
            usuarioBodegasCargando: false,
            bodegas: [],
            bodegasCargando: false,
            form: {
                usuario: null,
                bodega: null,
            },
            imagenPerfil: null,
            tipoUsuarios: [
                {
                    tipo_usuario_id: "FLUXSOLAR",
                    nombre_tipo_usuario: "FLUXSOLAR",
                },
                { tipo_usuario_id: "CLIENTE", nombre_tipo_usuario: "CLIENTE" },
                { tipo_usuario_id: "VISITA", nombre_tipo_usuario: "VISITA" },
            ],
            loadingActivarDesactivarUsuario:false,
            rolModuloPermiso:{}
        };
    },
    validations: {
        form: {
            usuario: { required },
            bodega: { required },

        },
    },
    mounted() {
        this.obtenerUsuarioBodegasLocal();
        let permisos = permiso.obtenerPermisos(this.user,this.$router.currentRoute.name)
        this.rolModuloPermiso = permisos
    },
    methods: {
        ...usuarioMethods,
        ...usuarioBodegaMethods,
        ...bodegaMethods,
        ...rolMethods,
        customLabel ({  nombre_usuario, codigo_usuario }) {
            return `${ nombre_usuario} – ${codigo_usuario}`
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        actualizarSegundosMensajeActualizacionModal(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacionModal = segundosMensajeActualizacion;
        },
        abrirModalNuevo() {
            this.resetForm();
            this.obtenerUsuariosLocal();
            this.obtenerBodegasLocal();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
        },
        abrirModalEditar(datos) {
            this.usuarioSeleccionado = datos;
            this.resetForm();
            this.obtenerUsuariosLocal();
            this.obtenerBodegasLocal();


            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        obtenerUsuarioBodegasLocal() {
            this.usuarioBodegasCargando = true;
            this.obtenerUsuarioBodegas()
                .then((res) => {
                    this.usuarioBodegas = res.body;
                    this.totalRows = this.usuarioBodegas.length
                    this.usuarioBodegasCargando = false;
                })
                .catch((error) => {
                    this.usuarioBodegasCargando = false;
                });
        },
        obtenerUsuariosLocal() {
            this.usuariosCargando = true;
            this.obtenerUsuarios()
                .then((res) => {
                    this.usuarios = res.body;
                    if(this.usuarioSeleccionado.email_usuario != undefined){
                        this.$v.form.usuario.$model = {
                            email_usuario: this.usuarioSeleccionado.email_usuario,
                            nombre_usuario: this.usuarioSeleccionado.nombre_usuario,
                        };
                    }
    
                    
                    this.usuariosCargando = false;
                })
                .catch((error) => {
                    this.usuariosCargando = false;
                });
        },
        obtenerBodegasLocal() {
            this.bodegasCargando = true;
            this.obtenerBodegas()
                .then((res) => {
                    this.bodegas = res.body;
                    if(this.usuarioSeleccionado.codigo_bodega != undefined){
                        this.$v.form.bodega.$model = {
                            codigo_bodega: this.usuarioSeleccionado.codigo_bodega,
                            nombre_bodega: this.usuarioSeleccionado.nombre_bodega,
                        };
                    }
                   
                    this.bodegasCargando = false;
                })
                .catch((error) => {
                    this.bodegasCargando = false;
                });
        },
        submit(e) {
            this.loading = true;
            this.submitted = true;
            this.$v.form.$touch();

            if (this.$v.form.$invalid) {
                this.loading = false;
                return;
            }

     
            if (this.esEditar) {
                
            }
            
            const usuario_bodega_id = this.usuarioSeleccionado.usuario_bodega_id;
            const id = this.$v.form.usuario.$model.id
            const codigo_usuario = this.$v.form.usuario.$model.codigo_usuario
            const nombre_usuario = this.$v.form.usuario.$model.nombre_usuario
            const email_usuario = this.$v.form.usuario.$model.email
            const codigo_bodega = this.$v.form.bodega.$model.codigo_bodega
            const nombre_bodega = this.$v.form.bodega.$model.nombre_bodega
            const token_usuario = this.$v.form.usuario.$model.token_usuario


            if (this.esEditar) {
                this.actualizarUsuarioBodega({
                    usuario_bodega_id,
                    id,
                    codigo_usuario,
                    nombre_usuario,
                    email_usuario,
                    codigo_bodega,
                    nombre_bodega,
                    token_usuario
                })
                    .then((res) => {
                        if (res.status == 202) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                        }

                        if (res.status == 204) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Usuario Bodega actualizado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerUsuarioBodegasLocal();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.mostrarModal = false;
                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            } else {
                this.crearUsuarioBodega({
                    id,
                    codigo_usuario,
                    nombre_usuario,
                    email_usuario,
                    codigo_bodega,
                    nombre_bodega,
                    token_usuario        
                })
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Usuario Bodega creado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "El usuario bodega ya existe!!!";
                            this.segundosMensajeActualizacionModal = this.segundos;
                            this.loading = false;
                            return
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerUsuarioBodegasLocal();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            }

            this.submitted = false;
        },
        resetForm() {
            this.form = {
                usuario: null,
                bodega: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        eliminarUsuarioBodegaLocal(item) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarUsuarioBodega(item.usuario_bodega_id)
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Usuario Bodega eliminado correctamente!!!";
                                    this.mostrarModal = false;
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerUsuarioBodegasLocal();
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        color(item) {
            if (item != null) {
                if (item == 1) {
                    return "bg-success";
                } else if (item == 2) {
                    return "bg-warning";
                } else if (item.toLowerCase() == "fluxsolar") {
                    return "bg-success";
                } else if (item.toLowerCase() == "cliente") {
                    return "bg-primary";
                } else if (item.toLowerCase() == "visita") {
                    return "bg-warning";
                }
            }
        },
       
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    computed: {
        ...authUsuarioComputed,
        // ...usuarioComputed,
        rows() {
            return 1;
        },

        /**
         * Todo list of records
         */
        todoList() {
            return this.todos.length;
        },
    },
};
</script>
